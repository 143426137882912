import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { ApiCallStatus } from "../utils/constants";
import { concat, uniqBy } from "lodash";
import { fetchSearchSuggestions } from "../main-component/PageFunctions/productFunctions";
import { fetchProductsPrices } from "../main-component/PageFunctions/priceFunctions";
import ReactGA from "react-ga4";

const useArgusSearch = () => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [products, setProducts] = useState(null);
	let auth0Token = null;

	const [searchResultsCallStatus, setSearchResultsCallStatus] = useState(ApiCallStatus.NotStarted);

	const fetchSearchSuggestionsAsync = async () => {
		if (isAuthenticated === true && !auth0Token) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchSearchSuggestions({
			auth0Token,
			searchTerm,
			setSearchResults,
			setStatusInProgress: () => setSearchResultsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setSearchResultsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setSearchResultsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setSearchResultsCallStatus(ApiCallStatus.Error)
		});
	};

	useEffect(() => {
		if (searchTerm && searchTerm.length > 2) {

			ReactGA.event({
				category: 'Ecommerce',
				action: 'search',
				value: searchTerm,
				nonInteraction: false
			});

			setProducts(null);

			fetchSearchSuggestionsAsync();
		} else {
			setSearchResults([]);
			setProducts(null);
		}
	}, [searchTerm]);

	useEffect(() => {
		if (!!searchResults.products) {
			const uniqueArray = uniqBy(searchResults.products, (x) => x.sku);
			setProducts(uniqueArray);
		}
	}, [searchResults?.products]);

	const allLoadingStates = [
		searchResultsCallStatus
	];

	const loading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return {
		setSearchTerm,
		products,
		loading
	}
}

export default useArgusSearch;