import { Box, Button, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import MakeModelYearForm from "../MakeModelYearForm";

const DesktopMakeModelYear = ({
	manufacturerOptions,
	handleUserVehicleChange,
	loading,
	variantOptions,
	yearOptions,
	modelOptions,
	userInput,
	submit
}) => {
	return (
		<Box sx={{ width: '100%', padding: 2, backgroundColor: '#262626', borderRadius: 2 }}>
			<Typography variant="h5" sx={{ mb: 3, textAlign: 'center', color: '#ffffff' }}>
				Search by Make Model Year
			</Typography>
			<MakeModelYearForm
				manufacturerOptions={manufacturerOptions}
				handleUserVehicleChange={handleUserVehicleChange}
				loading={loading}
				variantOptions={variantOptions}
				yearOptions={yearOptions}
				modelOptions={modelOptions}
				userInput={userInput}
				submit={submit}
				inputsPerRowDesktop={4}
				darkMode={true}
			/>
		</Box>
	);
}

export default DesktopMakeModelYear;