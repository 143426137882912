import React from "react";
import { Box, Button, Grid } from "@mui/material";
import Dropdown from "../basic/Dropdown";

const MakeModelYearForm = ({
	manufacturerOptions,
	handleUserVehicleChange,
	loading,
	variantOptions,
	yearOptions,
	modelOptions,
	userInput,
	submit,
	inputsPerRowMobile = 2,
	inputsPerRowDesktop = 4,
	darkMode
}) => {
	return (
		<>
			<Grid container spacing={2} alignItems="center" justifyContent="center">
				<Grid item xs={12 / inputsPerRowMobile} md={12 / inputsPerRowDesktop} >
					<Dropdown
						loading={loading}
						disabled={loading}
						value={userInput.manufacturer}
						onChange={handleUserVehicleChange("manufacturer")}
						options={manufacturerOptions}
						label="Make"
						darkMode={darkMode}
					/>
				</Grid>
				<Grid item xs={12 / inputsPerRowMobile} md={12 / inputsPerRowDesktop} >
					<Dropdown
						loading={loading}
						disabled={(!userInput.manufacturer) || loading}
						value={userInput.model}
						onChange={handleUserVehicleChange("model")}
						options={modelOptions}
						label="Model"
						darkMode={darkMode}
					/>
				</Grid>
				<Grid item xs={12 / inputsPerRowMobile} md={12 / inputsPerRowDesktop} >
					<Dropdown
						loading={loading}
						disabled={(!userInput.manufacturer && !userInput.model) || loading}
						value={userInput.variant}
						onChange={handleUserVehicleChange("variant")}
						options={variantOptions}
						label="Variant"
						darkMode={darkMode}
					/>
				</Grid>
				<Grid item xs={12 / inputsPerRowMobile} md={12 / inputsPerRowDesktop} >
					<Dropdown
						loading={loading}
						disabled={(!userInput.manufacturer && !userInput.model && !userInput.variant) || loading}
						value={userInput.year}
						onChange={handleUserVehicleChange("year")}
						options={yearOptions}
						label="Year"
						darkMode={darkMode}
					/>
				</Grid>
			</Grid>
			<Box sx={{mt: 4, textAlign: 'center'}}>
				<Button variant="contained"
				        onClick={submit}
				        size="small"
				        color="error"
				        type="button"
				        disabled={loading}>
					Search
				</Button>
			</Box>
		</>
	)
}

export default MakeModelYearForm;