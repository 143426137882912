import React, { useState } from "react";
import {
	Box,
	Button,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Modal,
	Typography,
	Card,
	CardContent,
	Divider, Alert, Link
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { PreferenceIds, ScreenSize } from "../../utils/constants";
import useSystemHook from "../../hooks/SystemHook";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const modalStyles = {
	[ScreenSize.Desktop]: { width: "50%", padding: 4, maxHeight: "80vh", overflowY: "auto" },
	[ScreenSize.Tablet]: { width: "80%", padding: 3, maxHeight: "80vh", overflowY: "auto" },
	[ScreenSize.Mobile]: { width: "95%", padding: 2, maxHeight: "80vh", overflowY: "auto" },
};

const SavedVehiclesModal = ({
	open,
	openChooseVehicleModal,
	setClose,
	currentUserVehicleId,
	userVehicles,
	upsertUserPreferenceAsync,
}) => {
	const { screenSize } = useSystemHook();
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const [selectedVehicleId, setSelectedVehicleId] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const menuOpen = Boolean(anchorEl);
	const currentVehicle = userVehicles.find((v) => v.userVehicleId === currentUserVehicleId);

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};

	const gotoRegisterPage = () => {
		return navigate("/register", {state: {from: location}});
	};

	const handleMenuClick = (event, id) => {
		setAnchorEl(event.currentTarget);
		setSelectedVehicleId(id);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSelectedVehicleId(null);
	};

	const closeModal = () => setClose();

	const handleSetActiveVehicle = async () => {
		await upsertUserPreferenceAsync(PreferenceIds.AccountCurrentUserVehicle, selectedVehicleId);
		setAnchorEl(null);
	};

	const handleSearchForParts = (vehicleId) => {
		const activeVehicle = userVehicles.find((v) => v.userVehicleId === vehicleId);
		if (!activeVehicle) return;

		const params = new URLSearchParams();
		params.append("make", activeVehicle.make);
		!!activeVehicle.model ? params.append("model", activeVehicle.model) : null;
		!!activeVehicle.variant ? params.append("variant", activeVehicle.variant) : null;
		!!activeVehicle.year ? params.append("year", activeVehicle.year) : null;

		window.location.href = `/products/all?${params.toString()}`
		closeModal();
	};

	return (
		<Modal open={open} onClose={closeModal}>
			<Box
				sx={{
					...modalStyles[screenSize],
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					bgcolor: "background.paper",
					boxShadow: 10,
					pb: 3,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<>
					{!!isAuthenticated &&
						<>
							<Typography variant="h6" fontWeight="bold" sx={{ p: 3, pt: 1 }}>Your Vehicles</Typography>

							{currentUserVehicleId && (
								<Box sx={{ px: 3, pb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<Button
										onClick={() => handleSearchForParts(currentUserVehicleId)}
										size="small"
										color="primary"
										type="button"
									>
										Search for {" "}
										{currentVehicle?.make}
										{currentVehicle?.model ? ` ${currentVehicle.model}` : ""}
										{currentVehicle?.variant ? ` ${currentVehicle.variant}` : ""}
										{currentVehicle?.year ? ` ${currentVehicle.year}` : ""}
										{" "} parts
									</Button>
								</Box>
							)}

							<Divider />

							<>
								{userVehicles.length > 0 &&
									<Grid container spacing={1} sx={{ px: 3, py: 2, flexGrow: 1 }}>
										{userVehicles.map((vehicle) => (
											<Grid item xs={12} sm={6} key={vehicle.userVehicleId}>
												<Card
													sx={{
														boxShadow: currentUserVehicleId === vehicle.userVehicleId ? "0px 0px 10px rgba(236, 51, 35, 0.7)" : 2,
														transition: "0.3s",
														height: "100%",
														display: "flex",
														flexDirection: "column",
														position: "relative",
														p: 2,
														border: "1px solid #ccc",
													}}
												>
													<IconButton
														size="small"
														onClick={(event) => handleMenuClick(event, vehicle.userVehicleId)}
														sx={{ position: "absolute", top: 8, right: 8 }}
													>
														<MoreVertIcon />
													</IconButton>
													<CardContent sx={{ textAlign: "center", mt: 2 }}>
														<Typography variant="subtitle2" fontWeight="bold">
															{vehicle.make} {vehicle.model}
														</Typography>
														<Typography variant="caption" color="textSecondary">
															{vehicle.variant} - {vehicle.year}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
										))}
									</Grid>
								}
								{userVehicles.length <= 0 &&
									<Box textAlign="center" sx={{ mt: 4 }}>
										<Typography variant="body2" color="textSecondary">
											No vehicles saved. Add one now!
										</Typography>
									</Box>
								}
							</>

							<Divider sx={{ mt: "auto" }} />

							<Box sx={{ px: 3, py: 2, textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
								<Button
									variant="contained"
									onClick={openChooseVehicleModal}
									size="small"
									color="error"
									type="button"
								>
									Add Another Vehicle
								</Button>
							</Box>

							<Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
								<MenuItem onClick={handleSetActiveVehicle}>
									<Typography variant="body2">Set as Active</Typography>
								</MenuItem>
								<MenuItem onClick={() => handleSearchForParts(selectedVehicleId)}>
									<Typography variant="body2">Search for Parts</Typography>
								</MenuItem>
							</Menu>
						</>
					}
					{!isAuthenticated &&
						<Grid container justifyContent="center" sx={{ mt: 2 }}>
							<Alert severity="warning">
								Please <Link href="/login">log in</Link> or <Link href="/register">create an account</Link> to access this feature.
							</Alert>
						</Grid>
					}
				</>
			</Box>
		</Modal>
	);
};

export default SavedVehiclesModal;
