import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const Dropdown = ({
	loading,
	disabled,
	value,
	onChange,
	options,
	label,
	darkMode
}) => {
	return (
		<Autocomplete
			loading={loading}
			disabled={disabled}
			value={value}
			onChange={onChange}
			options={options}
			getOptionLabel={(option) => option}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					variant="outlined"
					size="small"
					InputLabelProps={{style: {color: darkMode ? '#ffffff' : null}}} // White label text
					InputProps={{
						...params.InputProps,
						style: {color: darkMode ? '#ffffff' : null}, // White input text
						startAdornment: params.InputProps.startAdornment, // Preserve adornment (like icons)
						endAdornment: params.InputProps.endAdornment, // Preserve clear button
					}}
					sx={{
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								borderColor: darkMode ? '#888888' : null, // Darker gray border
								borderWidth: '2px', // Thicker border
							},
							'&:hover fieldset': {
								borderColor: darkMode ? '#ffffff' : null, // White border on hover
							},
							'&.Mui-focused fieldset': {
								borderColor: darkMode ? '#ffffff' : null, // White border when focused
							},
						},
						'& .MuiSvgIcon-root': {
							color: darkMode ? '#ffffff' : null, // White arrow color
						},
						'& .MuiAutocomplete-clearIndicator': {
							color: darkMode ? '#ffffff' : null, // White clear button color
						},
					}}
				/>
			)}
			clearOnEscape
			isOptionEqualToValue={(o, v) => o === v}
			sx={{
				'& .MuiAutocomplete-option': {
					fontSize: "0.2rem",
				},
			}}
		/>
	)
}

export default Dropdown;