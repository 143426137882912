import {
	AppBar,
	Badge,
	Box,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Toolbar,
	Button,
	Divider,
	Paper,
	Typography,
	Stack,
	styled,
	ListItem,
	CircularProgress,
	ListItemAvatar,
	Avatar
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import GarageOutlinedIcon from "@mui/icons-material/GarageOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from "@mui/icons-material/Close";
import Image from "../Image";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { capitalize, find, lowerCase, upperCase } from "lodash";
import useArgusSearch from "../../hooks/ArgusSearchHook";
import { SouthAfricanRand } from "../../utils/helpers";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import { useTheme } from "@mui/system";
import CustomButton from "../CustomButton";
import cssVariables from "../../utils/cssVariables";
import { ProductAvailability } from "../../utils/constants";

const MobileHeader = ({
	gotoLoginPage,
	gotoRegistrationPage,
	gotoCartPage,
	performLogout,
	cartItems,
	totalGarageItems,
	categoriesAndTypes,
	openSavedVehiclesModal,
	tecfinityAccountName,
	tecfinityAccountNumber,
	firstName,
	lastName
}) => {
	const { handleAddToCart } = useArgusCart();
	const { loading, pricingData, products, setSearchTerm } = useArgusSearch();
	const { isAuthenticated, isLoading } = useAuth0();
	const [searchInput, setSearchInput] = useState("");
	const [debouncedTerm, setDebouncedTerm] = useState(searchInput);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
	const [mobileCategoriesDrawerOpen, setMobileCategoriesDrawerOpen] = useState(false);
	const [mobileTypesDrawerOpen, setMobileTypesDrawerOpen] = useState(false);
	const [categoryToDisplay, setCategoryToDisplay] = useState(null);
	const searchInputRef = useRef(null);

	const theme = useTheme();

	const toggleMobileMenu = () => {
		setMobileMenuOpen(!mobileMenuOpen);
	};

	const toggleSearch = () => {
		setMobileSearchOpen(!mobileSearchOpen);
	};

	const toggleCategories = () => {
		setMobileCategoriesDrawerOpen(!mobileCategoriesDrawerOpen);
	};

	const toggleTypes = () => {
		setMobileTypesDrawerOpen(!mobileTypesDrawerOpen);
	};

	const handleCategoryClick = (category) => {
		setCategoryToDisplay(category);
		toggleTypes();
	}

	const handleCloseTypesClick = () => {
		setCategoryToDisplay(null);
		toggleTypes();
	}

	const StyledAppBar = styled(AppBar)(({ theme }) => ({
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.primary,
		zIndex: theme.zIndex.drawer + 1,
	}));

	const StyledInputBase = styled('input')(({ theme }) => ({
		width: '100%',
		padding: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.background.default,
		boxShadow: theme.shadows[1],
		border: 'none',
		outline: 'none',
	}));

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedTerm(searchInput);
		}, 300);

		return () => {
			clearTimeout(handler);
		};
	}, [searchInput]);

	useEffect(() => {
		if (debouncedTerm) {
			setSearchTerm(debouncedTerm);
		}
	}, [debouncedTerm]);

	useEffect(() => {
		if (mobileSearchOpen && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [mobileSearchOpen]);

	return (
		<>
			<StyledAppBar position="static">
				<Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<CustomButton edge="start"
					              onClick={toggleMobileMenu}
					              basic
					              startIcon={<MenuIcon />}
					              color="#333"
					/>
					<Image
						src="https://argusweb.azureedge.net/argus-web/logo.svg"
						alt="Logo"
						href="/"
						size="lg"
					/>
					<Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
						<IconButton sx={{ color: theme.palette.text.primary }} edge="end" onClick={toggleSearch}>
							<SearchIcon fontSize="small" />
						</IconButton>
						<IconButton edge="end" onClick={openSavedVehiclesModal}>
							<Badge badgeContent={totalGarageItems} color="error">
								<GarageOutlinedIcon sx={{ color: theme.palette.text.primary }} fontSize="small" />
							</Badge>
						</IconButton>
						<IconButton edge="end" onClick={gotoCartPage}>
							<Badge badgeContent={cartItems} color="error">
								<ShoppingCartIcon sx={{ color: theme.palette.text.primary }} fontSize="small" />
							</Badge>
						</IconButton>
					</Box>
				</Toolbar>
			</StyledAppBar>

			<Drawer
				anchor="left"
				open={mobileMenuOpen}
				onClose={toggleMobileMenu}
				sx={{ width: '75%', '& .MuiDrawer-paper': { width: '75%' } }}
			>
				<Box sx={{ padding: "2px", backgroundColor: theme.palette.background.default }}>
					<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
						<Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', paddingRight: "5px" }}>
							<Box sx={{ width: "100%" }} edge="start">
								<Image
									src="https://argusweb.azureedge.net/argus-web/logo.svg"
									alt="Logo"
									size="lg"
								/>
							</Box>
							<CustomButton sx={{ flexShrink: 0 }}
							              onClick={toggleMobileMenu}
							              edge="end"
							              startIcon={<CloseIcon />}
							              color="#333"
							              basic
							/>
						</Box>
					</Paper>
					<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
						<List>
							<ListItemButton href="/">
								<ListItemText primary="Home" />
							</ListItemButton>
							<Divider component="li" />
							<ListItemButton href="#" onClick={toggleCategories}>
								<ListItemText primary="Shop by category" />
								<IconButton sx={{ flexShrink: 0 }} edge="end">
									<ArrowForwardIcon fontSize="small" />
								</IconButton>
							</ListItemButton>
							<Divider component="li" />
							<ListItemButton href="/about">
								<ListItemText primary="About" />
							</ListItemButton>
							<Divider component="li" />
							<ListItemButton href="/faq">
								<ListItemText primary="FAQ" />
							</ListItemButton>
							<Divider component="li" />
							<ListItemButton href="/contact-us">
								<ListItemText primary="Contact Us" />
							</ListItemButton>
							<Divider component="li" />
							{!isLoading && (
								<>
									{!isAuthenticated ? (
										<>
											<ListItemButton href="#" onClick={gotoLoginPage}>
												<ListItemText primary="Login" />
											</ListItemButton>
											<Divider component="li" />
											<ListItemButton href="#" onClick={gotoRegistrationPage}>
												<ListItemText primary="Register" />
											</ListItemButton>
										</>
									) : (
										<ListItemButton href="#" onClick={performLogout}>
											<ListItemText primary="Logout" />
										</ListItemButton>
									)}
								</>
							)}
						</List>
					</Paper>
					<Box sx={{
						width: "100%",
						marginBottom: "1rem",
						display: "flex",
						flexDirection: "column",
						textAlign: "center" }}>
						{!!firstName && !!lastName &&
							<Typography variant="body2" sx={{ textDecoration: "underline"}}>
								User:&nbsp;{firstName} {lastName}
							</Typography>
						}
						{!!tecfinityAccountName &&
							<Typography variant="body2" sx={{ textDecoration: "underline"}}>
								Acc Name:&nbsp;{tecfinityAccountName}
							</Typography>
						}
						{!!tecfinityAccountNumber &&
							<Typography variant="body2" sx={{ textDecoration: "underline"}}>
								Acc No:&nbsp;{tecfinityAccountNumber}
							</Typography>
						}
					</Box>
				</Box>
			</Drawer>

			<Drawer anchor="top" open={mobileSearchOpen} onClose={toggleSearch} sx={{ height: '100vh', overflowY: 'auto' }}>
				<Box sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: theme.palette.background.paper }}>
					<StyledInputBase
						placeholder="Search…"
						autoFocus
						value={searchInput}
						onChange={(e) => setSearchInput(e.target.value)}
						ref={searchInputRef}
					/>
					<Typography
						variant="caption"
						sx={{ marginLeft: 2, cursor: 'pointer' }}
						onClick={toggleSearch}
					>
						Cancel
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: loading ? '100px' : '0px',
						transition: 'height 0.3s ease-in-out',
						opacity: loading ? 1 : 0,
					}}
				>
					{loading && <CircularProgress />}
				</Box>
				<List>
					{!!products && !!pricingData && products.length > 0 && (
						<>
							{products.map((product, index) => {
								const priceObject = pricingData.find((p) => p.sku === product.sku);
								const promoPrice = priceObject?.promo ?? 0;
								const promoInvoicePrice = priceObject?.promoInvoicePrice ?? 0;

								let itemPrice = priceObject?.price ?? 0;
								let invoicePrice = priceObject?.invoicePrice ?? 0;

								if (!!promoPrice && promoPrice > 0) {
									itemPrice = promoPrice;
									invoicePrice = promoInvoicePrice;
								}

								return (
									<React.Fragment key={index}>
										<ListItem
											alignItems="flex-start"
											component="a"
											href={`/product/${product.sku}`}
											sx={{
												textDecoration: 'none',
												color: 'inherit',
												'&:hover': { backgroundColor: theme.palette.action.hover },
											}}
										>
											<ListItemAvatar>
												<Avatar alt={product.sku} src={product.image} />
											</ListItemAvatar>
											<ListItemText
												primary={<Typography variant="caption">{product.name}</Typography>}
												secondary={
													<>
														<Typography variant="subtitle1">
															{SouthAfricanRand.format(itemPrice)}
														</Typography>
														{invoicePrice > 0 && (
															<Typography
																sx={{ display: 'inline', marginLeft: 1 }}
																component="span"
																variant="subtitle2"
																color="textSecondary"
															>
																(Invoice: {SouthAfricanRand.format(invoicePrice)})
															</Typography>
														)}
													</>
												}
											/>
											{product.availability !== ProductAvailability.No_Stock &&
												<IconButton
													onClick={(e) => {
														e.preventDefault();
														handleAddToCart({
															id: product.productId,
															name: product.name,
															vendor: product.vendor,
															image: product.image,
															sku: product.sku,
														});
													}}
													sx={{ ml: 2 }}
													color="error"
												>
													<AddShoppingCartIcon fontSize="small" />
												</IconButton>
											}
										</ListItem>
										{index < products.length - 1 && <Divider component="li" />}
									</React.Fragment>
								);
							})}
						</>
					)}
				</List>
			</Drawer>

			<Drawer
				anchor="left"
				open={mobileCategoriesDrawerOpen}
				onClose={toggleCategories}
				sx={{ width: '75%', '& .MuiDrawer-paper': { width: '75%' } }}
			>
				<Box sx={{ padding: "2px", backgroundColor: theme.palette.background.default }}>
					<Paper sx={{ width: "100%", marginBottom: "1rem", position: 'sticky', top: 0, zIndex: 1100 }}>
						<Box sx={{ width: "100%" }} edge="start">
							<Stack direction="column">
								<Box sx={{ alignItems: "left", paddingTop: "1rem" }} onClick={toggleCategories}>
									<CustomButton text="Menu"
									              startIcon={<ArrowBackIcon fontSize="small" />}
									              color={cssVariables.blueButtonColor}
									              basic />
								</Box>
								<Box sx={{ alignItems: "left", paddingLeft: "1rem" }}>
									<Typography variant="h6" gutterBottom>
										Shop by Category
									</Typography>
								</Box>
							</Stack>
						</Box>
					</Paper>
					<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
						<List>
							{categoriesAndTypes.map((category, i) => {
								const categoryName = capitalize(category.category.replaceAll("_", " ").toLowerCase());

								return (
									<div key={i}>
										<ListItemButton href="#" onClick={() => handleCategoryClick(category)}>
											<ListItemText primary={categoryName} />
											<IconButton sx={{ flexShrink: 0 }} edge="end">
												<ArrowForwardIcon fontSize="small" />
											</IconButton>
										</ListItemButton>
										{i < categoriesAndTypes.length - 1 && <Divider component="li" />}
									</div>
								);
							})}
						</List>
					</Paper>
				</Box>
			</Drawer>

			{!!categoryToDisplay && (
				<Drawer
					anchor="left"
					open={mobileTypesDrawerOpen}
					onClose={handleCloseTypesClick}
					sx={{ width: '75%', '& .MuiDrawer-paper': { width: '75%' } }}
				>
					<Box sx={{ padding: "2px", backgroundColor: theme.palette.background.default }}>
						<Paper sx={{ width: "100%", marginBottom: "1rem", position: 'sticky', top: 0, zIndex: 1100 }}>
							<Box sx={{ width: "100%" }} edge="start">
								<Stack direction="column">
									<Box sx={{ alignItems: "left", paddingTop: "1rem" }} onClick={handleCloseTypesClick}>
										<CustomButton text="Shop by category"
										              startIcon={<ArrowBackIcon fontSize="small" />}
										              color={cssVariables.blueButtonColor}
										              basic />
									</Box>
									<Box sx={{ alignItems: "left", paddingLeft: "1rem" }}>
										<Typography variant="h6" gutterBottom>
											{capitalize(categoryToDisplay.category.replaceAll("_", " ").toLowerCase())}
										</Typography>
									</Box>
								</Stack>
							</Box>
						</Paper>
						<Paper sx={{ width: "100%", marginBottom: "1rem" }}>
							<List>
								{categoryToDisplay.productTypes.map((type, i) => {
									const categorySlug = lowerCase(categoryToDisplay.category).replaceAll(" ", "_").trim();
									let typeName;
									let typeSlug;
									if (lowerCase(type) === "view all") {
										typeName = upperCase(type);
										typeSlug = `/products/${categorySlug}`;
									} else {
										typeName = capitalize(type.replaceAll("_", " ").trim());
										typeSlug = `/products/${categorySlug}/${type.toLowerCase()}`;
									}

									return (
										<div key={i}>
											<ListItemButton href={typeSlug}>
												<ListItemText primary={typeName} />
											</ListItemButton>
											{i < categoryToDisplay.productTypes.length - 1 && <Divider component="li" />}
										</div>
									);
								})}
							</List>
						</Paper>
					</Box>
				</Drawer>
			)}
		</>
	)
}

export default MobileHeader;