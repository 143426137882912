import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Modal,
	Typography,
	Icon, Alert
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ScreenSize } from "../../utils/constants";
import { fetchVehicleDetails } from "../../api/vehicleController";
import useSystemHook from "../../hooks/SystemHook";
import useMakeModelYear from "../../hooks/MakeModelYearHook";
import { cloneDeep } from "lodash";
import MakeModelYearForm from "../MakeModelYearForm";

const modalStyles = {
	[ScreenSize.Desktop]: {
		width: "50%",
		padding: 4,
	},
	[ScreenSize.Tablet]: {
		width: "70%",
		padding: 3,
	},
	[ScreenSize.Mobile]: {
		width: "90%",
		padding: 2,
	},
};

const modalState = {
	input: 1,
	fetching: 2,
	confirm: 3,
	saving: 4,
	saved: 5,
	error: 6
};

const ChooseVehicleModal = ({
	open,
	setClose,
	openSavedVehiclesModal,
	createUserVehicleAsync,
}) => {
	const { screenSize } = useSystemHook();
	const {
		yearOptions,
		manufacturerOptions,
		modelOptions,
		variantOptions,
		loading,
		userInput,
		setUserInput,
		clearYearOptions,
		clearVariantOptions,
		clearModelOptions,
		clearManufacturerOptions
	} = useMakeModelYear();
	const [currentModalState, setCurrentModalState] = useState(modalState.input);
	const [modalError, setModalError] = useState("");
	const [vehicleToSave, setVehicleToSave] = useState(null);

	const fetchVehicleDetailsAsync = async () => {
		await fetchVehicleDetails({
			make: userInput.manufacturer,
			model: userInput.model,
			variant: userInput.variant,
			year: userInput.year,
			setVehicleToSave,
			setStatusInProgress: () => setCurrentModalState(modalState.fetching),
			setStatusSuccess: () => setCurrentModalState(modalState.confirm),
			setStatusFailed: () => setCurrentModalState(modalState.error),
			setStatusError: () => setCurrentModalState(modalState.error)
		})
	}

	const handleUserVehicleChange = (name) => (event, newValue) => {
		let userInputCopy = cloneDeep(userInput);
		userInputCopy[name] = newValue;

		if (name === "manufacturer") {
			userInputCopy.model = null;
			userInputCopy.variant = null;
			userInputCopy.year = null;
			clearModelOptions();
			clearVariantOptions();
			clearYearOptions();
		}
		else if (name === "model") {
			userInputCopy.variant = null;
			userInputCopy.year = null;
			clearVariantOptions();
			clearYearOptions();
		}
		else if (name === "variant") {
			userInputCopy.year = null;
			clearYearOptions();
		}
		setUserInput(userInputCopy);
	};

	const closeModal = () => openSavedVehiclesModal();

	const searchInputVehicle = async () => {
		if (!!userInput.manufacturer) {
			await fetchVehicleDetailsAsync();
		}
	}

	const saveVehicle = async () => {
		if (!!vehicleToSave) {
			await createUserVehicleAsync({
				vehicleToSave,
				setStatusInProgress: () => setCurrentModalState(modalState.saving),
				setStatusSuccess: () => setCurrentModalState(modalState.saved),
				setStatusFailed: () => setCurrentModalState(modalState.error),
				setStatusError: () => setCurrentModalState(modalState.error)
			});
		}
	}

	const resetModal = () => {
		setVehicleToSave(null);
		setCurrentModalState(modalState.input)
	}

	return (
		<Modal open={open} onClose={closeModal}>
			<Box
				sx={{
					...modalStyles[screenSize],
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					bgcolor: "background.paper",
					boxShadow: 24,
					borderRadius: 2,
				}}
			>
				{(!!modalError || currentModalState === modalState.error) &&
					<Box textAlign="center">
						<Grid container sx={{ textAlign: "center" }}>
							<Grid item lg={12}>
								<Icon className="center-element" name="ban" color="red" size="massive"/>
							</Grid>
							<Grid item lg={12}>
								<Typography variant="h1" textAlign="center">Error</Typography>
							</Grid>
							<Grid item lg={12}>
								<Typography variant="body2">{modalError}</Typography>
							</Grid>
						</Grid>
					</Box>
				}
				{currentModalState === modalState.input &&
					<>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}>
							<Typography variant="body2" fontWeight="bold">Choose your vehicle</Typography>
							<IconButton onClick={closeModal} size="small">
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>
						<Typography variant="caption" sx={{ mt: 1, color: "#555" }}>
							Specify make, model, variant, and year to get the most accurate parts for your vehicle.
						</Typography>
						<Box sx={{ mt: 3 }}>
							<MakeModelYearForm
								manufacturerOptions={manufacturerOptions}
								handleUserVehicleChange={handleUserVehicleChange}
								loading={loading}
								variantOptions={variantOptions}
								yearOptions={yearOptions}
								modelOptions={modelOptions}
								userInput={userInput}
								submit={searchInputVehicle}
								inputsPerRowDesktop={2}
								inputsPerRowMobile={2}
							/>
						</Box>
					</>
				}
				{(currentModalState === modalState.fetching) &&
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ py: 5 }}>
						<CircularProgress />
						<Typography variant="body2" sx={{ mt: 2 }}>Loading...</Typography>
					</Box>
				}
				{(currentModalState === modalState.saving) &&
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ py: 5 }}>
						<CircularProgress />
						<Typography variant="body2" sx={{ mt: 2 }}>Saving your vehicle...</Typography>
					</Box>
				}
				{(currentModalState === modalState.confirm) &&
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ py: 5, px: 3 }}>
						<Typography variant="h6" sx={{ mb: 2 }}>
							Is this your vehicle?
						</Typography>

						{vehicleToSave ? (
							<Box sx={{ textAlign: "center", bgcolor: "#f5f5f5", p: 2, borderRadius: 2, width: "100%" }}>
								<Typography variant="body1"><strong>Make:</strong> {vehicleToSave.make}</Typography>
								{!!vehicleToSave.model && <Typography variant="body1"><strong>Model:</strong> {vehicleToSave.model}</Typography>}
								{!!vehicleToSave.variant && <Typography variant="body1"><strong>Variant:</strong> {vehicleToSave.variant}</Typography>}
								{!!vehicleToSave.year && <Typography variant="body1"><strong>Year:</strong> {vehicleToSave.year}</Typography>}
							</Box>
						) : (
							<CircularProgress sx={{ my: 2 }} />
						)}

						<Box sx={{ mt: 3, display: "flex", gap: 2 }}>
							<Button
								variant="contained"
								color="primary"
								onClick={saveVehicle}
							>
								Yes
							</Button>
							<Button
								variant="contained"
								color="error"
								onClick={resetModal}
							>
								No
							</Button>
						</Box>

						{!!vehicleToSave && (!vehicleToSave.model || !vehicleToSave.variant || !vehicleToSave.year) &&
							<Alert variant="outlined" severity="warning" sx={{ mt: 5 }}>
								Some vehicle details are missing. Without these, we can’t guarantee the most accurate parts for your vehicle.
							</Alert>
						}
					</Box>
				}
				{(currentModalState === modalState.saved) &&
					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ py: 5 }}>
						<CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
						<Typography variant="h6" sx={{ mt: 2 }}>Vehicle Saved Successfully!</Typography>
						<Button
							variant="contained"
							color="primary"
							sx={{ mt: 3 }}
							onClick={closeModal}
						>
							OK
						</Button>
					</Box>
				}
			</Box>
		</Modal>
	);
};

export default ChooseVehicleModal;
