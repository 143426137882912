import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MakeModelYearForm from "../MakeModelYearForm";

const MobileMakeModelYear = ({
	manufacturerOptions,
	handleUserVehicleChange,
	loading,
	variantOptions,
	yearOptions,
	modelOptions,
	userInput,
	submit
}) => {

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Box sx={{ width: '100%', padding: 2, backgroundColor: '#262626', borderRadius: 2 }}>
			<Typography variant="h6" sx={{ mb: 2, textAlign: 'center', color: '#ffffff' }}>
				Search by Make Model Year
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "center"}}>
				<Button variant="contained"
				        color="error"
				        size="small"
				        onClick={handleOpen}
				        startIcon={<TouchAppIcon />}
				        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					Find Parts for Your Vehicle
				</Button>
			</Box>

			<Modal
				open={open}
				onClose={handleClose}
				sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			>
				<Box sx={{
					width: '95%',
					maxWidth: '550px',
					bgcolor: '#ffffff',
					color: '#000000',
					borderRadius: 2,
					p: 3,
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<MakeModelYearForm
						manufacturerOptions={manufacturerOptions}
						handleUserVehicleChange={handleUserVehicleChange}
						loading={loading}
						variantOptions={variantOptions}
						yearOptions={yearOptions}
						modelOptions={modelOptions}
						userInput={userInput}
						submit={submit}
						inputsPerRowMobile={2}
					/>
				</Box>
			</Modal>
		</Box>
	)
}

export default MobileMakeModelYear;