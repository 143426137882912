import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Link, Modal, Typography } from "@mui/material";
import { storageKeys } from "../../api/storageKeys";
import { getItem, removeItem } from "../../utils/storageHelper";
import { createSearchParams, useNavigate } from "react-router-dom";
import useSystemHook from "../../hooks/SystemHook";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { ScreenSize } from "../../utils/constants";

const modalStyles = {
	[ScreenSize.Desktop]: { width: "35%", padding: 4 },
	[ScreenSize.Tablet]: { width: "70%", padding: 3 },
	[ScreenSize.Mobile]: { width: "90%", padding: 2 },
	[ScreenSize.Desktop]: { width: "35%", padding: 4 },
	[ScreenSize.Tablet]: { width: "70%", padding: 3 },
	[ScreenSize.Mobile]: { width: "90%", padding: 2 },
};

const SavedVehicleModal = ({ open, setOpen, openSavedVehiclesModal, setClose }) => {
	const { screenSize } = useSystemHook();
	const navigate = useNavigate();
	const [currentVehicle, setCurrentVehicle] = useState(null);
	const [currentVehicleName, setCurrentVehicleName] = useState("");

	useEffect(() => {
		const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);
		if (vehicleString) setCurrentVehicle(JSON.parse(vehicleString));
	}, []);

	useEffect(() => {
		if (currentVehicle) {
			const { make = "", model = "", variant = "", year = "" } = currentVehicle;
			setCurrentVehicleName(`${make} ${model} ${variant} ${year}`);
		}
	}, [currentVehicle]);

	const closeModal = () => setClose();

	const handleRemoveVehicleClick = () => {
		removeItem(storageKeys.GUEST_CURRENT_VEHICLE);
		closeModal();
		navigate(0);
	};

	const handleSearchForPartsClick = async () => {
		const vehicle = JSON.parse(getItem(storageKeys.GUEST_CURRENT_VEHICLE) || "{}");
		if (!vehicle) return closeModal();

		const params = vehicle.vyid
			? { vehicle_year_id: vehicle.vyid }
			: {
				make: vehicle.make,
				model: vehicle.model,
				variant: vehicle.variant,
				year: vehicle.year,
			};

		await navigate({ pathname: "/products", search: `?${createSearchParams(params)}` });
		closeModal();
	};

	const handleChangeVehicleClick = () => openSavedVehiclesModal();

	return (
		<Modal open={open} onClose={closeModal}>
			<Box
				sx={{
					...modalStyles[screenSize],
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					bgcolor: "background.paper",
					boxShadow: 10,
					borderRadius: 3,
					padding: 4,
				}}
			>
				{/* Header Section */}
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item display="flex" alignItems="center" gap={1}>
						<DirectionsCarIcon color="primary" sx={{ fontSize: 30 }} />
						<Typography variant="h6" fontWeight="bold">
							Your Selected Vehicle
						</Typography>
					</Grid>
					<IconButton onClick={closeModal} size="small">
						<CloseIcon fontSize="small" />
					</IconButton>
				</Grid>

				{/* Vehicle Information */}
				<Box
					sx={{
						mt: 2,
						bgcolor: "#f5f5f5",
						padding: 2,
						borderRadius: 2,
						textAlign: "center",
					}}
				>
					<Typography variant="body1" fontWeight="bold">
						{currentVehicleName || "No vehicle selected"}
					</Typography>
					<Typography variant="caption" sx={{ color: "#777" }}>
						This helps us find the best parts for your vehicle.
					</Typography>
				</Box>

				{/* Action Buttons */}
				<Grid container spacing={2} sx={{ mt: 3 }}>
					<Grid item xs={12} textAlign="center">
						<Button
							variant="contained"
							color="primary"
							onClick={handleSearchForPartsClick}
							size="large"
							sx={{ width: "100%" }}
						>
							Search for Parts
						</Button>
					</Grid>

					<Grid item xs={12} textAlign="center">
						<Link
							component="button"
							onClick={handleChangeVehicleClick}
							sx={{ textDecoration: "none", fontSize: "0.9rem", color: "#0073e6" }}
						>
							Choose a different vehicle
						</Link>
					</Grid>

					<Grid item xs={12} textAlign="center">
						<Link
							component="button"
							onClick={handleRemoveVehicleClick}
							sx={{ textDecoration: "none", fontSize: "0.9rem", color: "#d32f2f" }}
						>
							Browse without a vehicle
						</Link>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
};

export default SavedVehicleModal;
