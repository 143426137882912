import React, { Fragment, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useMediaQuery } from "react-responsive";
import { useCart } from "react-use-cart";
import {
	Box, Button,
	CircularProgress,
	Container, Grid,
	ImageList,
	ImageListItem, Link,
	Paper,
	Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Tabs,
	Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AccountType, ProductAvailability } from "../../utils/constants";
import { SouthAfricanRand } from "../../utils/helpers";
import "./style.css";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { cloneDeep, toLower } from "lodash";
import TabPanel from "../TabPanel/TabPanel";

const Product = ({
	product,
	pricingData,
	stockInfo,
	applicationFilters,
	applicationMakes,
	applicationModels,
	applicationVariants,
	applicationYears,
	setApplicationFilters,
	accountType,
	loading,
	applicationTabIsLoading,
	isAuthenticated
}) => {
	const {handleAddToCart} = useArgusCart();
	const navigate = useNavigate();
	const [tabValue, setTabValue] = useState(0);
	const [productPrice, setProductPrice] = useState(null);
	const [availabilityValue, setAvailabilityValue] = useState(null);
	const [colourForAvailability, setColourForAvailability] = useState(null);
	const [textForAvailability, setTextForAvailability] = useState(null);
	const [colourForQuality, setColourForQuality] = useState(null);
	const [textForQuality, setTextForQuality] = useState(null);

	const { addItem } = useCart();

	const applicationColumns = [
		{ key: "make", data: applicationMakes, filterType: "make" },
		{ key: "model", data: applicationModels, filterType: "model" },
		{ key: "variant", data: applicationVariants, filterType: "variant" },
		{ key: "year", data: applicationYears, filterType: null }, // No click handler needed for years
	];

	const onBackClick = () => {
		return navigate(-1);
	};

	const sanitizedData = () => ({
		__html: !!product ? DOMPurify.sanitize(product.html) : null
	});

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const handleApplicationFilterClick = (type, value) => {
		const filterCopy = cloneDeep(applicationFilters);
		filterCopy[type] = value;

		if (type === "make") {
			filterCopy.model = null;
			filterCopy.variant = null;
		} else if (type === "model") {
			filterCopy.variant = null;
		}

		setApplicationFilters(filterCopy);
	}

	const handleAddToCartClick = () => {
		handleAddToCart({
			id: product.productId,
			name: product.name,
			vendor: product.vendor,
			image: product.image,
			sku: product.sku
		});
	};

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	useEffect(() => {
		if (!!pricingData && pricingData.length > 0) {
			setProductPrice(pricingData[0]);
		}
	}, [pricingData]);

	useEffect(() => {
		if (!!product) {
			const tempAvailabilityValue = !!stockInfo && stockInfo.length > 0 ? stockInfo[0].quantityOnHand : product.availability;
			setAvailabilityValue(tempAvailabilityValue);
			switch (tempAvailabilityValue) {
				case ProductAvailability.InStock:
					setColourForAvailability("green");
					setTextForAvailability("This item is in stock at our warehouse. See our delivery policy to see when you can expect it.");
					break;
				case ProductAvailability.LowStock:
					setColourForAvailability("blue");
					setTextForAvailability("This item shows as having low stock in our warehouse. Please be aware that in some cases, this item may already be sold out and we may have to order this item in or refund your order.");
					break;
				case ProductAvailability.ThreeToFiveDays:
					setColourForAvailability("orange");
					setTextForAvailability("This item is in-stock at our suppliers. When you place an order for this item, we will collect it from our supplier and deliver it to you. Please see our delivery policy to see when you can expect it.");
					break;
				case ProductAvailability.No_Stock:
					setColourForAvailability("red");
					setTextForAvailability("This item is unfortunately out of stock, but more stock is on the way! Please contact us or regularly check our website to be notified when this item is back in stock.");
					break;
				default:
					setColourForAvailability("");
					setTextForAvailability("");
					break;
			}
			switch (product.quality) {
				case "A+":
					setColourForQuality("violet");
					setTextForQuality("Manufacturer is a recognised O.E. supplier.");
					break;
				case "A":
					setColourForQuality("blue");
					setTextForQuality("Aftermarket part manufactured to O.E. equivalent specifications.");
					break;
				case "B":
					setColourForQuality("teal");
					setTextForQuality("Economical replacement aftermarket part conforms to regulator requirements.");
					break;
				case "Other":
					setColourForQuality("olive");
					setTextForQuality("Applies to tools, accessories and non car specific parts.");
					break;
				default:
					setColourForQuality("");
					setTextForQuality("");
					break;
			}
		}
	}, [product, stockInfo]);

	const numberOfRows = Math.max(applicationMakes.length, applicationModels.length, applicationVariants.length, applicationYears.length);

	return (
		<Fragment>
			{isMobile === true &&
				<Container maxWidth="sm" sx={{ padding: 2 }}>
					<Button
						variant="text"
						size="small"
						startIcon={<ArrowBackIcon fontSize="small" />}
						onClick={onBackClick}
						sx={{ marginBottom: 2 }}
					>
						Back
					</Button>

					{loading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
							<CircularProgress />
						</Box>
					) : (
						<>
							{!!product ? (
								<Paper elevation={3} sx={{ padding: 2 }}>
									<ImageList cols={1}>
										<ImageListItem>
											<img
												src={product.image}
												alt={product.name}
												style={{ maxHeight: '250px', objectFit: 'contain' }}
											/>
										</ImageListItem>
									</ImageList>

									<Typography variant="h3" fontWeight="bold" sx={{ marginTop: 2 }}>
										{product.name}
									</Typography>

									<Typography variant="body2" color="textSecondary">
										SKU: {product.sku}
									</Typography>

									{product.vendor && (
										<Link href={`/products/all?vendor=${product.vendor}`} variant="body2" color="textSecondary">
											Vendor: {product.vendor}
										</Link>
									)}

									{!!productPrice && (
										<Box sx={{ marginTop: 3, marginBottom: 3, display: "block" }}>
											<Typography
												variant="h5"
												color="inherit"
												sx={{
													fontWeight: 'bold',
													textDecoration: productPrice.promo > 0 ? 'line-through' : 'none'
												}}
											>
												{productPrice.promo > 0 ? `Was: ${SouthAfricanRand.format(productPrice.price)}` : SouthAfricanRand.format(productPrice.price)}
											</Typography>

											{accountType === AccountType.Commercial && (
												<Typography variant="subtitle1" color="textSecondary">
													(Ex. VAT)
												</Typography>
											)}

											{!!productPrice?.invoicePrice &&
												productPrice.invoicePrice !== productPrice.price && (
													<Typography
														variant="subtitle1"
														color="textSecondary"
														sx={{
															textDecoration: productPrice.promoInvoicePrice > 0 ? 'line-through' : 'none'
														}}
													>
														Invoice: {productPrice.promoInvoicePrice > 0 ? `Was: ${SouthAfricanRand.format(productPrice.invoicePrice)}` : SouthAfricanRand.format(productPrice.invoicePrice)}
													</Typography>
												)}

											{productPrice.promo > 0 && (
												<Typography variant="h5" color="error" sx={{ fontWeight: 'bold', mt: 1 }}>
													Now: {SouthAfricanRand.format(productPrice.promo)}
												</Typography>
											)}

											{productPrice.promoInvoicePrice > 0 && productPrice.promoInvoicePrice !== productPrice.promo && (
												<Typography variant="subtitle1" color="error" sx={{ mt: 1 }}>
													Promo Invoice: {SouthAfricanRand.format(productPrice.promoInvoicePrice)}
												</Typography>
											)}
										</Box>
									)}

									{!!product.productCategory && (
										<Link href={`/products/${product.productCategory.toLowerCase()}`}
										      variant="body2"
										      sx={{ marginTop: 1, textDecoration: 'underline', marginRight: 3 }}
										>
											{product.productCategory.replaceAll('_', ' ')}
										</Link>
									)}
									{!!product?.productCategory && !!product?.productType && (
										<Link href={`/products/${product.productCategory.toLowerCase()}/${product.productType.toLowerCase()}`}
										      sx={{ textDecoration: 'underline' }}
										      variant="body2"
										>
											{product.productType.replaceAll('_', ' ')}
										</Link>
									)}

									{availabilityValue !== ProductAvailability.No_Stock && (
										<Button
											variant="contained"
											color="error"
											fullWidth
											size="small"
											sx={{ marginTop: 2 }}
											onClick={handleAddToCartClick}
										>
											Add to Cart
										</Button>
									)}

									{!!availabilityValue && (
										<Paper elevation={1} sx={{ marginTop: 2, padding: 1  }}>
											<Typography variant="subtitle1" sx={{ color: colourForAvailability, fontWeight: availabilityValue === ProductAvailability.No_Stock ? "bold" : "" }}>
												{availabilityValue}
											</Typography>
											<Typography variant="body2" sx={{ fontWeight: availabilityValue === ProductAvailability.No_Stock ? "bold" : ""}}>{textForAvailability}</Typography>
										</Paper>
									)}

									{!!product.quality && (
										<Paper elevation={1} sx={{ marginTop: 2, padding: 1 }}>
											<Typography variant="subtitle1" sx={{ color: colourForQuality }}>
												Quality: {product.quality}
											</Typography>
											<Typography variant="body2">{textForQuality}</Typography>
										</Paper>
									)}

									<Box sx={{ marginTop: 4 }}>
										<Tabs
											value={tabValue}
											onChange={handleTabChange}
											centered
											variant="fullWidth"
											textColor="error"
											indicatorColor="error"
										>
											<Tab label="Description" />
											{!!applicationMakes && isAuthenticated === true &&
												<Tab label="Applications" />
											}
										</Tabs>
										<TabPanel value={tabValue} index={0}>
											{product?.html && (
												<section>
													<div dangerouslySetInnerHTML={sanitizedData()}/>
												</section>
											)}
										</TabPanel>
										{!!applicationMakes && isAuthenticated === true &&
											<TabPanel value={tabValue} index={1}>
												<Box sx={{ padding: "16px", width: "100%" }}>
													<Grid container spacing={2}>
														<Grid item xs={12}>
															<Typography
																variant="body1"
																width="100%"
																sx={{ marginBottom: "16px", textAlign: "center" }}
															>
																Make
															</Typography>

															<Box
																sx={{
																	display: "flex",
																	flexDirection: "column",
																	gap: 1
																}}
															>
																{applicationMakes.map((make) => (
																	<Button
																		size="small"
																		fullWidth
																		onClick={() => handleApplicationFilterClick("make", make)}
																		disabled={!!applicationFilters.make && make === applicationFilters.make}
																		sx={{
																			textDecoration: make === applicationFilters.make ? "underline" : "none",
																			fontWeight: make === applicationFilters.make ? "bolder" : "none"
																		}}
																	>
																		{make}
																	</Button>
																))}
															</Box>
														</Grid>

														{!!applicationFilters.make && (
															<Grid item xs={12}>
																<Typography
																	variant="body1"
																	width="100%"
																	sx={{ marginBottom: "16px", textAlign: "center" }}
																>
																	Model
																</Typography>

																<Box
																	sx={{
																		display: "flex",
																		flexDirection: "column",
																		gap: 1
																	}}
																>
																	{applicationModels.map((model) => (
																		<Button
																			size="small"
																			fullWidth
																			onClick={() => handleApplicationFilterClick("model", model)}
																			disabled={!!applicationFilters.model && model === applicationFilters.model}
																			sx={{
																				textDecoration: model === applicationFilters.model ? "underline" : "none",
																				fontWeight: model === applicationFilters.model ? "bolder" : "none"
																			}}
																		>
																			{model}
																		</Button>
																	))}
																</Box>
															</Grid>
														)}

														{!!applicationFilters.model && (
															<Grid item xs={12}>
																<Typography
																	variant="body1"
																	width="100%"
																	sx={{ marginBottom: "16px", textAlign: "center" }}
																>
																	Variant
																</Typography>

																<Box
																	sx={{
																		display: "flex",
																		flexDirection: "column",
																		gap: 1
																	}}
																>
																	{applicationVariants.map((variant) => (
																		<Button
																			size="small"
																			fullWidth
																			onClick={() => handleApplicationFilterClick("variant", variant)}
																			disabled={!!applicationFilters.variant && variant === applicationFilters.variant}
																			sx={{
																				textDecoration: variant === applicationFilters.variant ? "underline" : "none",
																				fontWeight: variant === applicationFilters.variant ? "bolder" : "none"
																			}}
																		>
																			{variant}
																		</Button>
																	))}
																</Box>
															</Grid>
														)}

														{!!applicationFilters.variant && (
															<Grid item xs={12}>
																<Typography
																	variant="body1"
																	width="100%"
																	sx={{ marginBottom: "16px", textAlign: "center" }}
																>
																	Year
																</Typography>

																<Box
																	sx={{
																		display: "flex",
																		flexDirection: "column",
																		gap: 1
																	}}
																>
																	{applicationYears.map((year) => (
																		<Button
																			size="small"
																			fullWidth
																			sx={{
																				textDecoration: year === applicationFilters.year ? "underline" : "none",
																				fontWeight: year === applicationFilters.year ? "bolder" : "none"
																			}}
																		>
																			{year}
																		</Button>
																	))}
																</Box>
															</Grid>
														)}
													</Grid>
												</Box>
											</TabPanel>
										}
									</Box>
								</Paper>
							) : (
								<Box sx={{ textAlign: 'center', marginTop: 4 }}>
									<Typography variant="h5">No product found</Typography>
								</Box>
							)}
						</>
					)}
				</Container>
			}
			{isMobile === false &&
				<Container sx={{ padding: 4 }}>
					<>
						{product ? (
							<Paper elevation={3} sx={{ padding: 4 }}>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<Box sx={{ display: 'flex', justifyContent: 'center' }}>
											<img
												src={product.image}
												alt={product.name}
												style={{ maxHeight: '400px', objectFit: 'contain', width: '100%' }}
											/>
										</Box>
									</Grid>

									<Grid item xs={12} md={6}>
										<Box sx={{ display: "block" }}>
											<Typography variant="h6" sx={{ marginBottom: 2 }}>
												{product.name}
											</Typography>
										</Box>


										<Box sx={{ display: "block" }}>
											<Typography variant="caption" color="textSecondary" sx={{ marginBottom: 1 }}>
												SKU: {product.sku}
											</Typography>
										</Box>

										{product.vendor && (
											<Box sx={{ display: "block" }}>
												<Link href={`/products/all?vendor=${product.vendor}`}
												      variant="caption"
												      sx={{ marginBottom: 2, textDecoration: "none" }}
												>
													Vendor: {product.vendor}
												</Link>
											</Box>
										)}

										{!!productPrice && (
											<Box sx={{ marginTop: 3, marginBottom: 3, display: "block" }}>
												<Typography
													variant="h5"
													color="inherit"
													sx={{
														fontWeight: 'bold',
														textDecoration: productPrice.promo > 0 ? 'line-through' : 'none'
													}}
												>
													{productPrice.promo > 0 ? `Was: ${SouthAfricanRand.format(productPrice.price)}` : SouthAfricanRand.format(productPrice.price)}
												</Typography>

												{!!productPrice?.invoicePrice &&
													productPrice.invoicePrice !== productPrice.price && (
														<Typography
															variant="subtitle1"
															color="textSecondary"
															sx={{
																textDecoration: productPrice.promoInvoicePrice > 0 ? 'line-through' : 'none'
															}}
														>
															Invoice: {productPrice.promoInvoicePrice > 0 ? `Was: ${SouthAfricanRand.format(productPrice.invoicePrice)}` : SouthAfricanRand.format(productPrice.invoicePrice)}
														</Typography>
													)}

												{productPrice.promo > 0 && (
													<Typography variant="h5" color="error" sx={{ fontWeight: 'bold', mt: 1 }}>
														Now: {SouthAfricanRand.format(productPrice.promo)}
													</Typography>
												)}

												{accountType === AccountType.Commercial && (
													<Typography variant="subtitle1" color="textSecondary">
														(Ex. VAT)
													</Typography>
												)}

												{productPrice.promoInvoicePrice > 0 && productPrice.promoInvoicePrice !== productPrice.promo && (
													<Typography variant="subtitle1" color="error" sx={{ mt: 1 }}>
														Invoice: {SouthAfricanRand.format(productPrice.promoInvoicePrice)}
													</Typography>
												)}
											</Box>
										)}


										{!!product.productCategory && (
											<Box sx={{ display: "block", marginBottom: 1 }}>
												<Link href={`/products/${product.productCategory.toLowerCase()}`}
												      variant="caption"
												      sx={{ display: "block" }}>
													{product.productCategory.replaceAll('_', ' ')}
												</Link>
											</Box>
										)}
										{!!product?.productCategory && !!product?.productType && (
											<Box sx={{ display: "block" }}>
												<Link href={`/products/${product.productCategory.toLowerCase()}/${product.productType.toLowerCase()}`}
												      variant="caption"
												      sx={{ textDecoration: 'underline' }}
												>
													{product.productType.replaceAll('_', ' ')}
												</Link>
											</Box>

										)}

										{availabilityValue !== ProductAvailability.No_Stock && (
											<Button
												variant="contained"
												color="error"
												size="small"
												fullWidth
												sx={{ marginTop: 3 }}
												onClick={handleAddToCartClick}
											>
												Add to Cart
											</Button>
										)}

										{!!availabilityValue && (
											<Paper elevation={1} sx={{ marginTop: 3, padding: 2 }}>
												<Typography variant="subtitle2" sx={{ color: colourForAvailability, fontWeight: availabilityValue === ProductAvailability.No_Stock ? "bold" : "" }}>
													{availabilityValue}
												</Typography>
												<Typography variant="caption" sx={{ fontWeight: availabilityValue === ProductAvailability.No_Stock ? "bold" : "" }}>{textForAvailability}</Typography>
											</Paper>
										)}

										{!!product.quality && (
											<Paper elevation={1} sx={{ marginTop: 3, padding: 2 }}>
												<Typography variant="subtitle2" sx={{ color: colourForQuality }}>
													Quality: {product.quality}
												</Typography>
												<Typography variant="caption">{textForQuality}</Typography>
											</Paper>
										)}
									</Grid>
								</Grid>

								<Box sx={{ marginTop: 4 }}>
									<Tabs
										value={tabValue}
										onChange={handleTabChange}
										centered
										variant="fullWidth"
										textColor="error"
										indicatorColor="error"
									>
										<Tab label="Description" sx={{ textTransform: "none" }} />
										{!!applicationMakes && applicationMakes.length > 0 && isAuthenticated === true &&
											<Tab label="Applications" sx={{ textTransform: "none" }} />
										}
									</Tabs>
									<TabPanel value={tabValue} index={0}>
										{product?.html && (
											<section>
												<div dangerouslySetInnerHTML={sanitizedData()}/>
											</section>
										)}
									</TabPanel>
									{!!applicationMakes && applicationMakes.length > 0 && !!numberOfRows && isAuthenticated === true &&
										<TabPanel value={tabValue} index={1}>
											<Box sx={{ padding: "16px", width: "100%" }}>
												<Grid container spacing={2}>
													<Grid item md={12}>
														<TableContainer component={Paper} sx={{ mt: 2 }}>
															<Table>
																<TableHead>
																	<TableRow>
																		<TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
																			<Typography variant="body2" fontWeight="bold">
																				MAKE
																			</Typography>
																		</TableCell>
																		<TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
																			<Typography variant="body2" fontWeight="bold">
																				MODEL
																			</Typography>
																		</TableCell>
																		<TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
																			<Typography variant="body2" fontWeight="bold">
																				VARIANT
																			</Typography>
																		</TableCell>
																		<TableCell>
																			<Typography variant="body2" fontWeight="bold">
																				YEAR
																			</Typography>
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{Array.from({ length: numberOfRows }).map((_, index) => (
																		<TableRow key={index}>
																			{applicationColumns.map(({ key, data, filterType }, colIndex) => (
																				<TableCell
																					key={key}
																					sx={{
																						borderRight: colIndex < applicationColumns.length - 1 ? "1px solid rgba(224, 224, 224, 1)" : "none"
																					}}
																				>
																					{data[index] ? (
																						<Link
																							variant="caption"
																							href="#"
																							underline={applicationFilters[filterType] === data[index] ? "none" : "underline"}
																							onClick={!!filterType && applicationFilters[filterType] !== data[index]
																								? () => handleApplicationFilterClick(filterType, data[index])
																								: null
																							}
																							style={{
																								pointerEvents: applicationFilters[filterType] === data[index] ? "none" : "auto",
																								color: applicationFilters[filterType] === data[index] ? "gray" : "blue",
																							}}
																						>
																							{data[index]}
																						</Link>
																					) : (
																						""
																					)}
																				</TableCell>
																			))}
																		</TableRow>
																	))}
																</TableBody>
															</Table>
														</TableContainer>
													</Grid>
												</Grid>
											</Box>
										</TabPanel>
									}
								</Box>
							</Paper>
						) : (
							<Box sx={{ textAlign: 'center', marginTop: 4 }}>
								<Typography variant="h6">No product found</Typography>
							</Box>
						)}
					</>
				</Container>
			}
		</Fragment>
	);
};

export default Product;