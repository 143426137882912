import React, { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import {
	Avatar,
	Box,
	Button,
	Drawer,
	IconButton,
	Paper,
	Typography,
	Grid,
	TextField,
	InputAdornment
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import cssVariables from "../../utils/cssVariables";
import { find } from "lodash";

const AddedToCart = () => {
	const {
		addedToCartViewOpen,
		handleCloseAddedToCartView,
		itemAddedToCart,
		items,
		updateItemQuantity
	} = useArgusCart();

	const [cartProduct, setCartProduct] = useState(null);
	const [errorMessages, setErrorMessages] = useState({});

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	useEffect(() => {
		if (!itemAddedToCart || !items) {
			return;
		}

		const id = itemAddedToCart.id;
		const cartProductTemp = find(items, x => x.id === id);
		setCartProduct(cartProductTemp)
	}, [items, itemAddedToCart]);

	useEffect(() => {
		console.log("cartProduct", cartProduct)
	}, [cartProduct]);

	const handleQuantityChange = (id, sku, value) => {
		const quantity = parseInt(value);

		if (isNaN(quantity) || quantity <= 0) {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: 'Quantity must be a positive number.',
			}));
		} else if (quantity > 500) {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: 'For quantities greater than 500, please contact our sales team.',
			}));
		} else {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
			updateItemQuantity(id, quantity);
		}
	};

	const handleIncrement = (id, sku, currentQuantity) => {
		if (currentQuantity < 500) {
			updateItemQuantity(id, currentQuantity + 1);
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
		}
	};

	const handleDecrement = (id, sku, currentQuantity) => {
		if (currentQuantity > 1) {
			updateItemQuantity(id, currentQuantity - 1);
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
		}
	};

	return (
		<Fragment>
			{!!addedToCartViewOpen &&
				<Drawer anchor="right"
				        open={addedToCartViewOpen}
				        onClose={handleCloseAddedToCartView}
				        PaperProps={{
					        sx: { width: !isMobile ? '40%' : '75%' }
				        }}>
					<Box sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						p: 2,
						borderBottom: '1px solid #ccc',
					}}>
						<Typography variant="h6" sx={{ fontWeight: "bold" }}>Added to cart</Typography>
						<IconButton onClick={handleCloseAddedToCartView}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Box>

					<Box sx={{ p: 2 }}>
						<Paper elevation={3} sx={{ p: 2, mb: 2 }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Avatar src={itemAddedToCart.image}
								        alt={itemAddedToCart.name}
								        variant="square"
								        sx={{
									        width: !isMobile ? 80 : 64,
									        height: !isMobile ? 80 : 64,
									        mr: 2,
								        }}
								/>
								<Box>
									<Typography variant="caption">
										{itemAddedToCart.name}
									</Typography>
								</Box>
							</Box>

							{/* Quantity Input */}
							{!!cartProduct &&
								<Box xs={6} sm={4} md={2} textAlign="center" sx={{ mt: 2 }}>
									<Typography variant="body2" color="textSecondary" sx={{ marginBottom: '0.5rem' }}>
										Qty
									</Typography>
									<TextField
										variant="outlined"
										type="number"
										value={cartProduct.quantity}
										size="small"
										onChange={(e) => handleQuantityChange(itemAddedToCart.id, itemAddedToCart.sku, e.target.value)}
										inputProps={{
											min: 1,
											max: 500,
											step: 1,
											'aria-label': 'quantity',
											style: { textAlign: 'center', fontSize: '0.875rem' },
										}}
										error={!!errorMessages[itemAddedToCart.sku]}
										helperText={errorMessages[itemAddedToCart.sku]}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<IconButton
														onClick={() => handleDecrement(itemAddedToCart.id, itemAddedToCart.sku, cartProduct.quantity)}
														disabled={cartProduct.quantity <= 1}
														sx={{ padding: 0 }}
													>
														<RemoveIcon fontSize="small" />
													</IconButton>
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() => handleIncrement(itemAddedToCart.id, itemAddedToCart.sku, cartProduct.quantity)}
														disabled={cartProduct.quantity >= 500}
														sx={{ padding: 0 }}
													>
														<AddIcon fontSize="small" />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Box>
							}
						</Paper>

						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
						}}>
							<Button variant="contained"
							        color="error"
							        fullWidth
							        href="/cart"
							        size="small"
							        startIcon={<ShoppingCartIcon fontSize="small" />}
							>
								Go to cart
							</Button>
							<Button fullWidth
							        onClick={handleCloseAddedToCartView}
							        size="small"
							        sx={{
								        backgroundColor: "#00000000",
								        color: cssVariables.blueButtonColor,
								        "&:hover": {
									        backgroundColor: "#00000000",
									        textDecoration: "underline"
								        }
							        }}
							>
								Continue shopping
							</Button>
						</Box>
					</Box>
				</Drawer>
			}
		</Fragment>

	);
};
export default AddedToCart;